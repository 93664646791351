import React, {useRef, useState, useEffect, useLayoutEffect} from 'react';
import ReactPlayer from 'react-player';


function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }

        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}


function StreamPlayer({streamUrl, participant, chat, onPlayerReady}) {

    const playerRef = useRef(null);
    const [lastResetToLive, setLastResetToLive] = useState(Date.now());

    const [width, height] = useWindowSize();

    // Add new state for tracking play status
    const [isPlaying, setIsPlaying] = useState(true);

    // Modify state to handle both error and completion
    const [needsRestart, setNeedsRestart] = useState(false);

    // Constants for retry logic
    const MAX_RETRIES = 5;  // Maximum number of retry attempts
    const RETRY_WINDOW = 5 * 60 * 1000;  // 5 minutes in milliseconds
    const RETRY_DELAY = 1000;  // Delay between retries in milliseconds

    // Retry tracking state
    const [retryCount, setRetryCount] = useState(0);
    const [firstRetryTimestamp, setFirstRetryTimestamp] = useState(null);

    // Handle pause/resume
    const handlePlayPause = () => {
        setIsPlaying(!isPlaying);
    };

    // Reset retry counters
    const resetRetryCounters = () => {
        setRetryCount(0);
        setFirstRetryTimestamp(null);
        setNeedsRestart(false);
        console.log('Retry counters reset');
    };

    // Handle playback issues (error or completion)
    const handlePlaybackIssue = () => {
        const now = Date.now();
        
        // Initialize first retry timestamp if this is the first retry
        if (firstRetryTimestamp === null) {
            setFirstRetryTimestamp(now);
            setRetryCount(1);
            console.log('First retry attempt initiated');
        } else {
            // Check if we're still within the retry window
            if (now - firstRetryTimestamp > RETRY_WINDOW) {
                // Reset counters if we're outside the window
                console.log('Retry window expired, resetting counters');
                resetRetryCounters();
                setFirstRetryTimestamp(now);
                setRetryCount(1);
            } else {
                // Increment retry count if within window
                setRetryCount(prev => prev + 1);
                console.log(`Retry attempt ${retryCount + 1} of ${MAX_RETRIES}`);
            }
        }

        setNeedsRestart(true);
        setIsPlaying(false);
    };

    // Handle playback completion
    const handlePlaybackEnded = () => {
        console.log('Playback ended, treating as playback issue');
        handlePlaybackIssue();
    };

    // Recovery mechanism
    useEffect(() => {
        if (needsRestart) {
            // Check if we've exceeded retry limits
            if (retryCount >= MAX_RETRIES) {
                console.log(`Maximum retry attempts (${MAX_RETRIES}) reached within ${RETRY_WINDOW/60000} minutes`);
                console.log('Stopping retry attempts. Manual refresh may be needed');
                setNeedsRestart(false);
                return;
            }

            console.log(`Attempting recovery - Retry ${retryCount} of ${MAX_RETRIES}`);
            const timer = setTimeout(() => {
                if (playerRef.current) {
                    console.log('Restarting playback and seeking to live position');
                    setNeedsRestart(false);
                    setIsPlaying(true);
                    playerRef.current.seekTo('live');
                }
            }, RETRY_DELAY);

            return () => clearTimeout(timer);
        }
    }, [needsRestart, retryCount]);

    // Reset retry counters on successful playback
    const handlePlay = () => {
        setIsPlaying(true);
        resetRetryCounters();
        console.log('Playback started successfully, reset retry counters');
    };

    const handleBufferEnd = () => {
        if (Date.now() - lastResetToLive > 2000) {
            playerRef.current.seekTo('live');
            setLastResetToLive(Date.now());
        }
    };

    const handlePlayerReady = () => {
        setIsPlaying(true);  // Force play when player is ready
        onPlayerReady();
    };

    // Add useEffect to ensure playing state is true on mount
    useEffect(() => {
        setIsPlaying(true);
    }, []); // Empty dependency array means this runs once on mount

    // First useEffect for bandwidth monitoring
    useEffect(() => {
        const checkBandwidth = () => {
            const connection = navigator.connection || 
                             navigator.mozConnection || 
                             navigator.webkitConnection;

            if (connection) {
                console.log(
                    '################\n',
                    {
                        bandwidth: `${connection.downlink} Mbps`,
                        networkType: connection.effectiveType,
                        rtt: `${connection.rtt} ms`,
                    },
                    '\n################'
                );
            }
        };

        // Initial check
        checkBandwidth();

        const connection = navigator.connection || 
                         navigator.mozConnection || 
                         navigator.webkitConnection;
        
        if (connection) {
            connection.addEventListener('change', checkBandwidth);
            return () => connection.removeEventListener('change', checkBandwidth);
        }
    }, []);

    // New useEffect for monitoring YouTube quality
    useEffect(() => {
        const checkQuality = () => {
            const player = playerRef.current?.getInternalPlayer();
            if (player?.getPlaybackQuality) {
                console.log(
                    '################\n',
                    {
                        youtubeQuality: player.getPlaybackQuality()
                    },
                    '\n################'
                );
            }
        };

        // Check quality every second
        const intervalId = setInterval(checkQuality, 10000);

        return () => clearInterval(intervalId);
    }, []);

    // Also keep the quality change event handler
    const handleQualityChange = (quality) => {
        console.log(
            '################\n',
            {
                youtubeQuality: quality
            },
            '\n################'
        );
    };

    return (

        <ReactPlayer
            ref={playerRef}
            url={streamUrl}
            className={'videoPlayer'}
            playing={isPlaying}
            onPause={handlePlayPause}
            controls={true}
            onBufferEnd={handleBufferEnd}
            onReady={handlePlayerReady}
            width={ participant || chat ? (width - 340)  : (height - 70) * 1.775}
            height={ participant || chat ? (width - 341)/1.775  : (height - 71)}
            onPlaybackQualityChange={handleQualityChange}
            onError={handlePlaybackIssue}
            onEnded={handlePlaybackEnded}
            key={needsRestart ? Date.now() : 'initial'}
        />
    );
}

export default StreamPlayer;
